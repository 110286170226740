<template>
  <b-modal
    ref="pickupPoints"
    header-bg-variant="primary"
    centered
    size="lg"
    :title="$t('Puntos de retiro')"
    :ok-title="$t('Cerrar')"
    ok-only
  >
    <b-row>
      <b-col>
        <b-alert :show="showMessage" fade dismissible :variant="variant">
          {{message}}
        </b-alert>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col md="8">
        <b-form-group
          v-if="isOpen"
          horizontal
          :label-cols="2"
          breakpoint="md"
          :label="$t('Puntos de retiro')"
          label-for="inputPickupPoints">
          <autocomplete
            id="inputPickupPoints"
            name="inputPickupPoints"
            :maxElements="1"
            :provider="searchPickupPoint"
            :filterThisElements="carrierSelected.pickupPointsIds"
            :selectLabel="$t('Enter para agregar Punto de retiro')"
            v-model="selPickupPoint">
          </autocomplete>
        </b-form-group>
      </b-col>
      <b-col>
        <b-button class="ml-auto" variant="primary" @click="handleAddPickupPoint" :disabled="selPickupPoint.length === 0 || loading">{{ $t('Agregar Punto de retiro') }}</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          style="max-height: 200px;"
          show-empty
          striped
          hover
          small
          sticky-header="true"
          :items="pickupPointsInCarrier"
          :fields="fields"
          :fixed="true"
          :empty-text="$t('No hay puntos de retiro para mostrar')"
        >
          <template slot="actions" slot-scope="row" >
            <b-button @click="removePickupPoint(row.item)"><i class="fa fa-trash" aria-hidden="true"></i></b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { CarrierServiceMixin, PickupPointServiceMixin } from '@/mixims/CarrierServiceMixin.js'
import autocomplete from '@/components/common/vue-autocomplete'
export default {
  components: { autocomplete },
  mixins: [ CarrierServiceMixin, PickupPointServiceMixin ],
  data () {
    return {
      carrierSelected: {
        pickupPointsIds: []
      },
      showMessage: 0,
      variant: 'success',
      message: '',
      loading: false,
      selPickupPoint: [],
      fields: [
        { key: 'name', label: this.$t('Nombre'), 'class': 'text-left' },
        { key: 'actions', label: this.$t('Eliminar Punto de retiro'), 'class': 'text-right' }
      ],
      allPickupPoints: [],
      isOpen: false,
      pickupPointsInCarrier: []
    }
  },
  methods: {
    async openModal (carrier) {
      this.isOpen = true
      await this.fetchData()
      await this.handleGetCarrier(carrier.item.id)
      this.$refs.pickupPoints.show()
    },
    async fetchData () {
      this.loading = true
      await this.getPickupPoints(null).then(
        response => {
          this.loading = false
          this.allPickupPoints = response.body
        },
        error => {
          console.log(error)
          this.loading = false
          return []
        }
      )
    },
    async handleGetCarrier (id) {
      this.loading = true
      this.selPickupPoint = []
      await this.getCarrier(id).then(
        response => {
          this.carrierSelected = response.body
          this.loading = false
          if (this.carrierSelected.pickupPointsIds === null) this.carrierSelected.pickupPointsIds = []
        }
      )
      await this.searchPickupPoint()
    },
    handleShowMessage (data) {
      this.showMessage = 3
      this.variant = data.variant
      this.message = data.message
    },
    callUpdateEndpoint (successMessage, errorMessage) {
      this.loading = true
      this.showMessage = 0
      this.updateCarrier(this.carrierSelected).then(() => {
        this.loading = false
        this.handleGetCarrier(this.carrierSelected.id)
        this.selPickupPoint.splice(0, this.selPickupPoint.length)
        this.handleShowMessage({variant: 'success', message: this.$t(successMessage)})
      }).catch(er => {
        this.handleShowMessage({variant: 'danger', message: this.$t(errorMessage)})
      })
    },
    handleAddPickupPoint () {
      this.carrierSelected.pickupPointsIds.push(this.selPickupPoint[0].id)
      this.callUpdateEndpoint('Punto de retiro agregado exitosamente.', 'Ocurrió un error al agregar el Punto de retiro')
    },
    removePickupPoint (pickupPoint) {
      this.carrierSelected.pickupPointsIds = this.carrierSelected.pickupPointsIds.filter(pickupId => pickupId !== pickupPoint.id)
      this.callUpdateEndpoint('Punto de retiro eliminado exitosamente.', 'Ocurrió un error al eliminar el Punto de retiro')
    }
  },
  watch: {
    carrierSelected () {
      if (this.carrierSelected.pickupPointsIds.length) {
        this.pickupPointsInCarrier = this.carrierSelected.pickupPointsIds.flatMap(id => this.allPickupPoints.filter(pp => pp.id === id) || [])
      } else {
        this.pickupPointsInCarrier =  []
      }
    }
  }
}
</script>

<style>

</style>